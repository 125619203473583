import React, { ComponentType, useEffect, useState } from "react";
import styled from "styled-components";

import NavigationBar from "common/_components/navigation_bar";
import AppCommunicator from "common/_helpers/app_communicator";
import { compareVersion } from "common/_utils/compare_version";
import copyClipboard from "common/_utils/copy_clipboard";
import { useToastMessage } from "common/_components/toast/_hooks/use_toast_message";
import color from "assets/color";
import ic_share from "assets/icon/ic_share.svg";
import VisibleProps from "common/_types/visible_props";
import { useNavigate } from "react-router";

function withNavigationBar<T>(
  WrappedComponent: ComponentType<T>,
  options?: { hasShareButton?: boolean; lastPage?: boolean },
) {
  return function ParentComponent(props: any) {
    const [isVisibleNavigation, setIsVisibleNavigation] = useState(false);
    const showToastMessage = useToastMessage();
    const navigate = useNavigate();

    useEffect(() => {
      const appVersion = AppCommunicator.getAppVersion();
      const platform = AppCommunicator.getPlatform();

      if (platform === "iOS" && compareVersion(appVersion, "6.25.0") >= 0) {
        setIsVisibleNavigation(true);
      } else if (
        platform === "Android" &&
        compareVersion(appVersion, "6.25.0") >= 0
      ) {
        setIsVisibleNavigation(true);
      }

      if (!platform || !appVersion) {
        setIsVisibleNavigation(true);
      }
    }, []);

    const handleClickShareButton = () => {
      copyClipboard(window.document.location.href);
      showToastMessage("링크가 복사되었어요");
    };

    const handleClickBack = () => {
      if (options?.lastPage) {
        AppCommunicator.goBack();
      }

      navigate(-1);
    };

    return (
      <Container isVisible={isVisibleNavigation}>
        {isVisibleNavigation && (
          <NavigationBar onClickBack={handleClickBack}>
            {options?.hasShareButton && (
              <ShareButton onClick={handleClickShareButton}>
                <ShareIcon src={ic_share} alt={""} />
                {"공유하기"}
              </ShareButton>
            )}
          </NavigationBar>
        )}
        <WrappedComponent {...props} />
      </Container>
    );
  };
}

const Container = styled.div<VisibleProps>`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: ${(props: VisibleProps) =>
    props.isVisible ? "5.8rem" : "0rem"};
`;

const ShareButton = styled.div`
  position: absolute;
  right: 2rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 1.2rem;
  background: ${color.gray200};
  color: ${color.gray700};
  font-size: 1.4rem;
  font-weight: 700;
  border-radius: 1.5rem;
  line-height: 1.6rem;
`;

const ShareIcon = styled.img`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.4rem;
`;

export default withNavigationBar;
