import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router";

import PlatformType from "domain/enum/platform_type";
import ic_speaker from "assets/icon/ic_speaker.svg";
import ic_carrot_right from "assets/icon/ic_carrot_right.svg";
import color from "assets/color";
import useRecommendedNotices from "../../_hooks/use_recommended_notices";
import hackleManager from "common/_helpers/hackle_manager";
import Notice from "domain/entity/notice";

function RecommendedContentList() {
  const location = useLocation();
  const navigate = useNavigate();

  const id = location.pathname.split("/").pop();

  const { recommendedNotices } = useRecommendedNotices({
    platform: location.pathname.includes("android")
      ? PlatformType.ANDROID
      : PlatformType.IOS,
    currentNoticeId: id ? parseInt(id) : 0,
  });

  const handleClickNotice = (notice: Notice) => {
    hackleManager.track("ic_click_top_picks_contents", {
      touchpoint: notice.title,
    });
    navigate(location.pathname.replace(/\/\d+/, `/${notice.id}`), {
      replace: true,
    });
  };

  if (recommendedNotices.length === 0) return null;

  return (
    <Container>
      <Title>{"이런 콘텐츠는 어때요?"}</Title>
      {recommendedNotices.map((notice) => (
        <ListItem
          key={`notice-${notice.id}`}
          onClick={() => {
            handleClickNotice(notice);
          }}
        >
          <IconBox>
            <SpeakerIcon src={ic_speaker} alt={"-"} />
          </IconBox>
          <Text>{notice.title}</Text>
          <CarrotRightIcon src={ic_carrot_right} alt={">"} />
        </ListItem>
      ))}
    </Container>
  );
}

const Container = styled.div`
  padding: 4rem 2.4rem 8.2rem 2.4rem;
  background: ${color.gray100};
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.6rem;
  color: ${color.gray700};
  margin-bottom: 1.6rem;
`;

const ListItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background: ${color.white};
  padding: 2rem 1.6rem;
  border-radius: 1.6rem;
  margin-top: 1.2rem;
`;

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 2.4rem;
  background: ${color.gray50};
`;

const SpeakerIcon = styled.img``;

const Text = styled.span`
  font-size: 1.6rem;
  color: ${color.gray700};
  margin-left: 0.8rem;
`;

const CarrotRightIcon = styled.img`
  position: absolute;
  right: 1.6rem;
  width: 0.8rem;
  height: 1.6rem;
`;

export default RecommendedContentList;
