import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import AppCommunicator from "common/_helpers/app_communicator";
import AlarmCard from "./_components/alarm_card";
import RecommendedContentList from "./_components/recommended_content_list";
import useNotice from "./_hooks/use_notice";
import LoadingIndicator from "common/_components/loading_indicator";
import withNavigationBar from "../_helpers/with_navigation_bar";
import hackleManager from "common/_helpers/hackle_manager";
import { NoticeType } from "domain/entity/notice";

function NoticeDetailPage() {
  const location = useLocation();
  const id = location.pathname.split("/").pop();

  const containerRef = useRef<HTMLDivElement>(null);
  const htmlAreaRef = useRef<HTMLDivElement>(null);

  const [isLoadedContent, setIsLoadedContent] = useState(false);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const { notice } = useNotice(id ? parseInt(id) : 0);

  useEffect(() => {
    const noticeDetailPageElement = containerRef.current;

    if (noticeDetailPageElement !== null) {
      noticeDetailPageElement.addEventListener("scroll", reportScroll);
    }
  }, []);

  useEffect(() => {
    if (notice) {
      const noticeId = notice.id;
      AppCommunicator.logGaEvent(`check_notice_${noticeId}`);
      setIsLoadedContent(false);

      if (containerRef.current) {
        containerRef.current.scrollTo(0, 0);
      }
    }
  }, [notice]);

  useEffect(() => {
    const noticeDetailPageElement = containerRef.current;

    if (scrollPercentage >= 90 && notice) {
      const noticeId = notice.id;
      AppCommunicator.logGaEvent(`complete_to_read_notice_${noticeId}`);
    }

    if (noticeDetailPageElement !== null && scrollPercentage >= 90) {
      noticeDetailPageElement.removeEventListener("scroll", reportScroll);
    }
  }, [scrollPercentage]);

  useEffect(() => {
    // INFO: HTML 이미지가 전부 Load된 이후 하단 컴포넌트가 뜰 수 있도록 함.
    const htmlArea = htmlAreaRef.current;

    if (!htmlArea) return;

    let loadedImagesCount = 0;
    let images: HTMLImageElement[] = [];

    const handleImageLoad = () => {
      loadedImagesCount += 1;

      if (loadedImagesCount === images.length) {
        setIsLoadedContent(true);
      }
    };

    const initializeImages = (props: { firstFlag?: boolean }) => {
      const { firstFlag } = props;

      images = Array.from(htmlArea.getElementsByTagName("img"));
      loadedImagesCount = 0;

      if (images.length === 0 && !firstFlag) {
        setIsLoadedContent(true);
      } else {
        images.forEach((image) => {
          if (image.complete) {
            handleImageLoad();
          } else {
            image.addEventListener("load", handleImageLoad);
            image.addEventListener("error", handleImageLoad);
          }
        });
      }
    };

    initializeImages({ firstFlag: true });

    const observer = new MutationObserver(() => {
      initializeImages({ firstFlag: false });
    });
    observer.observe(htmlArea, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
      images.forEach((image) => {
        image.removeEventListener("load", handleImageLoad);
        image.removeEventListener("error", handleImageLoad);
      });
    };
  }, [htmlAreaRef]);

  const reportScroll = useCallback(() => {
    const container = containerRef.current;

    if (container !== null) {
      const height = container.scrollHeight - container.clientHeight;
      const currentScrollPercentage = (container.scrollTop / height) * 100;
      setScrollPercentage(currentScrollPercentage);
    }
  }, []);

  const handleClickAlarm = () => {
    hackleManager.track("ic_click_top_picks_contents", {
      touchpoint: notice?.title,
    });
  };

  return (
    <Container ref={containerRef}>
      <div
        ref={htmlAreaRef}
        dangerouslySetInnerHTML={{
          __html: notice?.html || "",
        }}
      />
      {!isLoadedContent && (
        <LoadingSection>
          <LoadingIndicator />
        </LoadingSection>
      )}
      {isLoadedContent && notice?.type === NoticeType.CONTENT && (
        <>
          <AlarmCard onClick={handleClickAlarm} />
          <RecommendedContentList />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const LoadingSection = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  background: white;
`;

export default withNavigationBar(NoticeDetailPage, { hasShareButton: true });
